import classNames from "classnames";
import banner from "../../../assets/images/banner.webp";

export const MainBanner = () => {
  return (
    <div className="relative h-fit">
      <div
        className={classNames(
          "font-bold text-center mt-4 w-full", // common
          "px-4 text-3xl mb-4", // mobile
          "md:px-8 md:text-6xl md:mb-8",
          "lg:text-8xl"
        )}
      >
        Наши не придут, все наши это мы!
      </div>
      <img className="w-full rounded" src={banner} alt="Главный Банннер. Идут рабочие" />
    </div>
  );
};
