import { FC } from "react";

type Props = {
  bankName: string;
  cardNumber: string;
};

export const BankCard: FC<Props> = (props) => {
  const { bankName, cardNumber } = props;

  return (
    <div className="rounded-lg bg-gray-100 p-4 shadow-sm sm:p-6 lg:max-w-xl lg:p-8">
      <div className="text-size-xs font-bold">{bankName}</div>
      <div className="text-size-default font-bold">{cardNumber}</div>
    </div>
  );
};
