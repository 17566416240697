import classNames from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type ButtonVariant = "primary" | "secondary" | "ghost";
type Size = "default" | "large";

type Props = PropsWithChildren<HTMLAttributes<HTMLButtonElement>> & {
  variant?: ButtonVariant;
  size?: Size;
  href?: string;
};

export const Button: FC<Props> = (props) => {
  const {
    href,
    variant = "primary",
    size = "default",
    className,
    ...rest
  } = props;

  const cn: string = classNames(
    buttonClassNames,
    classNamesByVariant[variant],
    className,
    classNamesBySize[size]
  );

  const button = <button className={cn} {...rest} />;

  if (href) {
    return (
      <a className={cn} href={href} target="_blank" rel="noreferrer">
        {rest.children}
      </a>
    );
  }

  return button;
};

const buttonClassNames =
  "block text-center transition-colors duration-300 ease-in-out font-bold rounded";

const primary =
  "text-white bg-primary hover:bg-primary-hover active:bg-primary-active";

const ghost = "text-black bg-ghost hover:bg-ghost-hover active:bg-ghost-active";

const secondary =
  "text-black bg-secondary hover:bg-secondary-hover active:bg-secondary-active";

const classNamesByVariant: Record<ButtonVariant, string> = {
  ghost: ghost,
  primary: primary,
  secondary: secondary,
};

const classNamesBySize: Record<Size, string> = {
  default: "py-2 px-4",
  large: classNames("py-3 px-8 text-size-xs", "py-4 px-8 text-size-sm"),
};
