import { FC, HTMLAttributes, PropsWithChildren } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

export const Container: FC<PropsWithChildren<Props>> = (props) => {
  const { children, ...rest } = props;

  return (
    <div className="container mx-auto px-4" {...rest}>
      {children}
    </div>
  );
};
