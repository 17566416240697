import youtube from "../assets/youtube.png";
import dzen from "../assets/dzen.png";
import rutube from "../assets/rutube.png";
import vk from "../assets/vk.png";
import telegram from "../assets/telegram.png";
import discord from "../assets/discord.png";
import boosty from "../assets/boosty.png";
import ymusic from "../assets/ymusic.png";
import { NetworkThumb, NetworkThumbProps } from "./network-thumb";
import { ContentContainer } from "../../../shared";

const getLink = (name: string, src: string, href: string) => ({
  name,
  src,
  href,
});

const links: NetworkThumbProps[] = [
  getLink("Youtube", youtube, "https://www.youtube.com/@tribun_marx"),
  getLink("Telegram", telegram, "https://t.me/marche_des_femmes"),
  getLink("VK", vk, "https://vk.com/marche_des_femmes"),
  getLink("Discord", discord, "https://discord.com/invite/ztmhnyADZa"),

  getLink("Boosty", boosty, "https://boosty.to/tribun"),
  getLink(
    "Яндекс.Музыка",
    ymusic,
    "https://music.yandex.ru/album/22134835?dir=desc&activeTab=about"
  ),
  getLink("Rutube", rutube, "https://rutube.ru/channel/17988135/"),
  getLink("Дзен", dzen, "https://dzen.ru/tribun"),
];

export const SocialNetworks = () => {
  return (
    <ContentContainer title="Мы в социальных сетях">
      <div className="mt-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4">
        {links.map((link) => (
          <NetworkThumb key={link.name} {...link} />
        ))}
      </div>
    </ContentContainer>
  );
};
