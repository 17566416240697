import {
  Header,
  HelpUs,
  JoinTribun,
  MainBanner,
  SocialNetworks,
} from "./features";
import { Library } from "./features/library";
import { Container } from "./shared";

const App = () => {
  return (
    <div className="bg-white min-h-screen">
      <Header />
      <MainBanner />
      <Container>
        <SocialNetworks />
        <JoinTribun />
        <Library />
        <HelpUs />
      </Container>
    </div>
  );
};

export default App;
