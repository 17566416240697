import classNames from "classnames";
import { Button, ContentContainer } from "../../../shared";
import banner from "../assets/banner.jpg";
import { BankCard } from "./bank-card";

export const HelpUs = () => {
  return (
    <ContentContainer>
      <div className="flex flex-col gap-4 mx-auto">
        <img
          className="object-contain origin-top rounded"
          src={banner}
          alt="Наше единство не рушимо, советский плакат"
        />
        <div className="max-w-[1000px] flex flex-col gap-4 mx-auto">
          <div
            className={classNames(
              "my-2 text-size-xs",
              "md:my-4 md:text-size-sm"
            )}
          >
            Мы все живём в непростой этап классовой борьбы, и любая помощь
            рабочему движению от каждого трудящегося невероятно ценна. Наша сила
            в единстве, и только вместе мы сможем сделать мир лучше, а людей
            счастливей.
            <br />
            <br />
            Делитесь нашими видео, приводите друзей на наши интернет-площадки, и
            вливайтесь в наши регулярные активности! А если имеется возможность
            - поддержите нас донатом, или подпиской на Boosty. Рот Фронт,
            товарищи!
          </div>
          <div className="grid grid-cols-1 gap-4 md:gap-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
              <Button href="https://boosty.to/tribun" size="large">
                Boosty
              </Button>
              <Button
                href="https://www.donationalerts.com/r/marche_des_femmes"
                size="large"
              >
                Donation Alerts
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
              <BankCard
                bankName="Сбербанк"
                cardNumber={"2202 2018 4883 4368"}
              />
              <BankCard bankName="Tinkoff" cardNumber={"5536 9140 3334 6069"} />
            </div>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};
