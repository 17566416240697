import { Container } from "../../../shared";
import logo from "../assets/logo.svg";

export const Header = () => {
  return (
    <Container>
      <nav className="py-4 flex items-center justify-center">
        <img className="h-[40px] md:h-auto" src={logo} alt="logo" />
      </nav>
    </Container>
  );
};
