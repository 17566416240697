import classNames from "classnames";
import { Button, ContentContainer } from "../../../shared";
import banner from "../assets/banner.jpeg";

export const Library = () => {
  return (
    <ContentContainer
      title="📖 Библиотека"
      description="Наша редакция запускает онлайн-библиотеку, чтобы делиться знаниями с читателями и распространять марксистскую литературу. А также публиковать материалы, которые редакция использует в своей работе"
    >
      <img className="w-full rounded" src={banner} alt="Библиотека" />
      <Button
        href="https://wiki.tribun-marx.ru"
        className={classNames(
          "mt-4 w-full",
          "py-4 pb-5 text-size-sm", // mobile
          "md:py-8 md:pb-9 md:text-size-large"
        )}
      >
        Перейти в библиотеку
      </Button>
    </ContentContainer>
  );
};
