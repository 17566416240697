import classNames from "classnames";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type Props = HTMLAttributes<HTMLDivElement> & {
  title?: string;
  description?: string;
};

export const ContentContainer: FC<PropsWithChildren<Props>> = (props) => {
  const { children, title, description, ...rest } = props;

  return (
    <div className={classNames("py-12", "md:py-20")} {...rest}>
      {title && (
        <h2 className="text-size-large sm:text-size-subtitle lg:text-size-title font-bold text-center">
          {title}
        </h2>
      )}
      {description && (
        <p className="mt-8 text-size-sm md:text-size-large text-center text-gray-500">
          {description}
        </p>
      )}
      <div className={classNames("mt-4", "md:mt-8")}>{children}</div>
    </div>
  );
};
