import classNames from "classnames";
import { Button, ContentContainer } from "../../../shared";
import banner from "../assets/banner.jpeg";

export const JoinTribun = () => {
  return (
    <ContentContainer title="✊ Помоги делу пролетариата">
      <img
        className="w-full rounded"
        src={banner}
        alt="Наше единство не рушимо, советский плакат"
      />
      <Button
        href="https://docs.google.com/forms/d/e/1FAIpQLSfKfvYV25PU1Ls9yU2w5ogHaiE6RZjfCVn1KCK7Ul6dUPlrxQ/viewform"
        className={classNames(
          "mt-4 w-full",
          "py-4 pb-5 text-size-sm", // mobile
          "md:py-8 md:pb-9 md:text-size-large"
        )}
      >
        Присоединиться к Трибун
      </Button>
    </ContentContainer>
  );
};
