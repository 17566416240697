import { FC } from "react";

type Props = {
  name: string;
  href: string;
  src: string;
};

export const NetworkThumb: FC<Props> = (props) => {
  const { src, href, name } = props;

  return (
    <a
      className="hover:scale-105 active:scale-100 transition flex flex-col items-center"
      key={src}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="" src={src} alt={name}></img>
    </a>
  );
};
export type { Props as NetworkThumbProps };
